import { Endpoints } from 'Roblox';
import { getNavigationContainer } from './searchConstants';

const { getAbsoluteUrl } = Endpoints;

/** Looks for `data-show-moderation-portal-link="true"` set by web-platform to determine
 * if moderation portal link should be visible.
 */
export const isModerationPortalLinkVisible = (): boolean =>
  getNavigationContainer()?.dataset.showModerationPortalLink === 'true';

export const moderationPortalUrl = getAbsoluteUrl('/moderation-portal/');
